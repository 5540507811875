import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Section from '../layout/Section';
import heroBg from '../../images/home/hero-bg.png';
import { useViewport } from '../context/ViewportProvider';
import { colors } from '../../includes/style';

export const SiteName = () => {
  //Limitation: Only one useStaticQuery can be used per file
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          name
        }
      }
    }
  `);

  return <>{data.site.siteMetadata.name}</>;
};

const StyledSection = styled(Section)`
  background: ${colors.blue} url(${heroBg});
  background-repeat: no-repeat;
  background-position: center bottom;
  /* background-attachment: scroll; */
  background-size: cover;
  color: #fff;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;
  }

  .viewport-info{
    border: 1px solid white;
    padding: 16px;

    h3{
      margin: 0;
    }
  }
`;

const Title = styled.h1`
  font-weight: bold;
`;

// Regarding H1 tags and SEO https://moz.com/blog/h1-seo-experiment
export default function Hero() {
  const { height, width, scroll } = useViewport();

  return (
    <StyledSection id="hero">
      <Title><SiteName /></Title>
      <div className="viewport-info">
        <h3>Viewport info</h3>
        <div>Height: {height}</div>
        <div>Width: {width}</div>
        <div>Scroll: {scroll}</div>
      </div>
    </StyledSection>
  );
}
